import CalculatorInit from '../CalculatorInit';

const init = options => {
  const defaultCalculatorOptions = {
    ID: '9',
    calculatorType: 'DefaultCalculator',
    calculatorTypeForAnalytics: 'SmallCalculator',
    parent: '[data-calc-short]',
    format: 'json',
    options: {
      deafault: {
        academicLevelDefault: 1,
        pagesDefault: 1,
      },
    },
    callback: () => {
      const loader = document.querySelector('[data-calc-short]').querySelector('.Loader');

      if (loader) {
        document.querySelector('[data-calc-short]').removeChild(loader);
      }
      document.querySelector('.Calc').classList.add('Calc--loaded');
    },
  };

  const calculatorOptions = {
    ...defaultCalculatorOptions,
    ...options,
  };

  CalculatorInit(calculatorOptions);
};

const ShortCalculator = options => {
  init(options);
};

const toolTip = document.querySelector('.CalcFull .pay-parts__info');
if (toolTip) {
  toolTip.addEventListener('click', e => e.target.classList.toggle('active'));
}

const payInTwoCheckbox = document.querySelector('.Calc .pay-parts__checkbox__input');
if (payInTwoCheckbox) payInTwoCheckbox.checked = payInTwoCheckbox.defaultChecked; // set to default after coming back to the page

export default ShortCalculator;
