/**
 * Description: JS for mastersthesiswriting.com
 * Version: 1.0.0
 * Last update: 2015/05/01
 * Author: UVOCorp
 *
 */
import ShortCalculator from '../../components/Calculator';
/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function () {
  return this.each(function (_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({appearance: 'none', '::-ms-expand': 'none'});
    $icon.css({pointerEvents: 'none'});
  })
};

(function (window, document) {
  function init() {
    var element = document.getElementById('CommonPage');

    if (element) {
      var pageName = element.getAttribute('data-page');
      var siteId = element.getAttribute('data-site-id');
      var policies = element.getAttribute('data-policies');
      var policyId = element.getAttribute('data-policy-id');

      let widgetOptions = {
        siteId,
        pageName,
      };

      if (policies && policyId) {
        widgetOptions = { siteId, pageName, policies, policyId };
      }

      window.commonPagesWidget.widget(element, widgetOptions);
    }
  }

  if (typeof window.commonPagesWidget !== 'undefined') {
    init();
  } else {
    var script = document.createElement('script');
    script.src = '/assets/js/commonPagesWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);



document.addEventListener('DOMContentLoaded', function () {

  //toggle topmenu
  $('.toggle-menu').click(function() {
    var menuBox = $('.topmenu-nav');
    if ($(menuBox).hasClass('visible') == false) {
      $(menuBox).addClass('visible');
    } else {
      $(menuBox).removeClass('visible');
    }
    return false;
  });

  if ($('#testimonials-slides').length)
    $('#testimonials-slides').owlCarousel({
      dots: false,
      items: 2,
      margin: 10
    });

  if ($('#main-slides').length && $(window).width() >= 768) {
    $('#main-slides').owlCarousel({
      dots: false,
      items: 1,
      autoplay: true,
      autoplayTimeout: 5000,
      animateOut: 'fadeOut',
      mouseDrag: false,
      touchDrag: false
    });
  }

    $('.accordion').kwicks({
      maxSize: '30%',
      spacing: 0,
      duration: 100,
      autoResize: false,
      behavior: 'menu'
    });


  //add class "selected" for item select
  if ($.fn.uvoAutocomplete) {
    $('.place-your-order select').uvoAutocomplete({
      onOpen: function (e) {
        var lebelEl = this.label;
        $(this._listElement.children()).each(function () {
          if ($(this).text() == lebelEl) {
            $(this).addClass('selected')
          }
        });
      }
    });
  }

  //for inquiry page
  $('.show-hide').find('.hidden').hide();
  $('#inq-done-more').click(function () {
    if (!$(this).hasClass('less')) {
      $(this).parents('.show-hide').find('.hidden').slideDown(
        500);
      $(this).text('Read less').addClass('less');
    } else {
      $(this).parents('.show-hide').find('.hidden').slideUp(
        500);
      $(this).text('Read more').removeClass('less');
    }
  });
  $('.plagcheckc aside#rightplagpanel').clone().appendTo(
    '.plagcheckc').addClass('rgt-spec-plag').attr('id',
    'rightplagpanel_2');


});

//CALC MINI
$.getScript('/assets/js/calc.js', function () {
  if ($('[data-calc-mini]').length > 0) {
    new Calc({
      ID: '9',
      calculatorType: 'DefaultCalculator',
      format: 'html',
      options: {
        deafault: {
          deadlinesDefault: [66513],
          academicLevelDefault: 1,
          paperTypesDefault: 1,
          pagesDefault: 2
        }
      }

    });
  }

  if ($('[data-calc-short]').length > 0) {
    ShortCalculator();
  }
});

$(window).scroll(function () {
  (function () {
    var headerHeight = $('.main-header').height();
    if ($(window).width() > 640) {
      if ($(window).scrollTop() <= headerHeight) {
        if ($('.rc-sticky-sidebar').length > 0) {
          $('.rc-sticky-sidebar').css('transform', 'translateY(0)');
        }
      } else if ($(window).scrollTop() > headerHeight) {
        if ($('.rc-sticky-sidebar').length > 0) {
          $('.rc-sticky-sidebar').css('transform', 'translateY(' + headerHeight + 'px)');
        }
      }
    }
  })();
});

document.addEventListener('DOMContentLoaded', function(){
  $(document).on('click', '.toggle-list .toggle-link', function(){
    if ($(this).next().hasClass('show')) {
      $(this).next().slideUp(500);
      $(this).next().removeClass('show').parent().removeClass('active');
    } else {
      $(this).next().slideDown(500);
      $(this).next().addClass('show').parent().addClass('active');
    }
    return false;
  });
});

