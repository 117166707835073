const isPageConfig =
  window.pageConfig &&
  Object.keys(window.pageConfig).length > 0 &&
  window.pageConfig.constructor === Object;

const CalculatorInit = options => {
  const pageCalculatorConfig =
    isPageConfig && window.pageConfig.calculator !== undefined
      ? window.pageConfig.calculator
      : undefined;

  const calcOptions = options;

  calcOptions.options.deafault = {
    ...calcOptions.options.deafault,
    ...pageCalculatorConfig,
  };

  if (window.Calc) {
    new Calc(calcOptions); /* eslint-disable-line */
  }
};

export default CalculatorInit;
